import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

import { Container, Row, Col, Center } from "./shared/grid"
import { H3, P } from "./shared/text"
import Button from "./shared/button"

import AFRFooterMobileIMG from "../images/mobile-footer.jpg"
import AFRFooterTabletIMG from "../images/tablet-footer.jpg"
import AFRFooterDesktopIMG from "../images/desktop-footer.jpg"
import SMHFooterMobileIMG from "../images/mobile-footer-smh.jpg"
import SMHFooterTabletIMG from "../images/tablet-footer-smh.jpg"
import SMHFooterDesktopIMG from "../images/desktop-footer-smh.jpg"
import AGEFooterMobileIMG from "../images/mobile-footer-age.jpg"
import AGEFooterTabletIMG from "../images/tablet-footer-age.jpg"
import AGEFooterDesktopIMG from "../images/desktop-footer-age.jpg"

import AFRLogoIMG from "../images/logo-new.svg"
import SMHLogoIMG from "../images/smh-logo.svg"
import AGELogoIMG from "../images/age-logo.svg"
import MailIconImg from "../images/Mail.svg"
import GlowIMG from "../images/glow.png"

const Styles = {
  Wrapper: styled.footer``,

  Subscribe: styled.div`
    transform: translateY(40px);
    margin-top: 40px;
  `,

  MDSubscribe: styled.img`
    width: 100%;
  `,

  Bottom: styled.div`
    padding-top: 100px;
    padding-bottom: 40px;

    ${props => props.theme === 'afr' ?
      `background-color: black;`: 
      `background: #0A162E;
       background-image:url(${GlowIMG});
       background-position:bottom center;
       background-size:2656px 299px;
       background-repeat:no-repeat;`
    }

    a {
      text-decoration: none;
      color: white;
    }

    span {
      padding-top: 10px;
      font-size: 10px;
      font-weight: 400;
      display: inline-block;

      img {
        width: 14px;
        margin-right: 6px;
      }
    }

    @media (min-width: 768px) {
      span {
        padding-top: 10px;
        font-size: 10px;
        font-weight: 400;
        display: inline-block;

        img {
          width: 14px;
          margin-right: 6px;
        }
      }
    }

    @media (min-width: 1038px) {
      span {
        padding-top: 10px;
        font-size: 12px;
        font-weight: 400;
        display: inline-block;

        img {
          width: 14px;
          margin-right: 6px;
        }
      }
    }
  `,

  SubscribeBlock: styled.div`
    background-size: 100%;
    width: 100%;
    height: 480px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat:no-repeat;

    ${props => {
      if(props.theme === 'afr'){
        return `background-image: url(${AFRFooterMobileIMG});`
      }
      if(props.theme === 'smh'){
        return `background-image: url(${SMHFooterMobileIMG});`
      }
      if(props.theme === 'age'){
        return `background-image: url(${AGEFooterMobileIMG});`
      }
    }}

    p{
      width:95%;
      margin-left:auto;
      margin-right:auto;
    }

    button{
      margin-top:0;
    }

    @media (min-width: 768px) {
        height: 340px;
        
        ${props => {
          if(props.theme === 'afr'){
            return `background-image: url(${AFRFooterTabletIMG});`
          }
          if(props.theme === 'smh'){
            return `background-image: url(${SMHFooterTabletIMG});`
          }
          if(props.theme === 'age'){
            return `background-image: url(${AGEFooterTabletIMG});`
          }
        }}

        p{
          width:75%;
        }
    }
    @media (min-width: 1038px) {
        height: 284px;
        
        ${props => {
          if(props.theme === 'afr'){
            return `background-image: url(${AFRFooterDesktopIMG});`
          }
          if(props.theme === 'smh'){
            return `background-image: url(${SMHFooterDesktopIMG});`
          }
          if(props.theme === 'age'){
            return `background-image: url(${AGEFooterDesktopIMG});`
          }
        }}

        p{
          width:65%;
        }
    }
  `,

  SMLogo: styled.img`
    width:200px;

    @media (min-width: 768px) {
      display:none;
      margin-bottom:40px;
    }
  `,

  MDLogo: styled.img`
    display: none;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 768px) {
      display: block;
      padding: 10px 0px;
      margin-bottom: 20px;
      width:200px;
    }
  `,

  Details: styled.ul`
    list-style:none;
    color:white;
    margin-top:80px;

    @media (min-width: 768px) {
      margin-top:20px;
    }

    li{
      height:30px;
      display:block;
      line-height:0px;

      span{
        font-size:14px;
      }

      img{
        margin-right:8px;
        height:10px;
      }
    }

    @media (min-width: 768px) {

      li{
        display:inline-block;
        margin-left:6px;
        margin-right:0px;

        &:after{
          content:"  | "
        }

        &:last-child{
          &:after{
            content:""
          }
        }
      }

    }
    
  `,

}

const Footer = ({ data }) => {

  const block = data.step.blocks[0].block
  let LOGO = AFRLogoIMG
  let isIE11 =  false
  let BTN = <Button style="inline" label="Enquire now" />
  
  if (typeof window !== `undefined`){
    isIE11 = !!window.MSInputMethodContext && !!document.documentMode
  }

  if(process.env.GATSBY_THEME === 'afr'){
    LOGO = AFRLogoIMG
  }

  if(process.env.GATSBY_THEME === 'smh'){
    LOGO = SMHLogoIMG
    BTN = <Button style="blue" label="Enquire now" />
  }

  if(process.env.GATSBY_THEME === 'age'){
    LOGO = AGELogoIMG
    BTN = <Button style="blue" label="Enquire now" />
  }

  return(
    <Styles.Wrapper>
      <Styles.Subscribe>
        <Container>
          <Row>
            <Col md={12}>
              <Styles.SubscribeBlock theme={process.env.GATSBY_THEME}>
                <div>
                  <H3>{block.title}</H3>
                  <P>{block.description.text}</P>
                  <div onClick={() => scrollTo('#form')}>
                    {BTN}
                  </div>
                </div>
              </Styles.SubscribeBlock>
            </Col>
          </Row>
        </Container>
      </Styles.Subscribe>
      <Styles.Bottom theme={process.env.GATSBY_THEME}>
        <Container>
          <Row>
            <Col md={12}>
              <Center>
                <Styles.SMLogo src={LOGO} />
                <Styles.MDLogo src={LOGO} />
              </Center>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Center>
                <Styles.Details color="white">
                  <li>
                    <a href={`mailto:${process.env.GATSBY_CONTACT_EMAIL}`}>
                    {!isIE11 && <img src={MailIconImg} />}<span>{process.env.GATSBY_CONTACT_EMAIL}</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.afr.com/privacy-policy"><span>Privacy policy</span></a>
                  </li>
                  <li>
                    <span>Copyright © 2020. All Rights Reserved.</span>
                  </li>
                </Styles.Details>
              </Center>
            </Col>
          </Row>
        </Container>
      </Styles.Bottom>
    </Styles.Wrapper>
  )
}

const query = graphql`
{
  step:allContentfulBlock(limit:1, filter:{slug:{eq:"step-ahead"}}){
    blocks:edges{
      block:node{
        slug
        title
        subtitle
        description{
          text:description
        }
        images{
          file{
            url
          }
        }
        video{
          file{
            url
          }
        }
        ctaText
        references{
          ... on ContentfulTestimonial{
            name
            quote{
              text:quote
            }
            title
            company
          }
        }
      }
    }
  }
}
`

export default props => (
  <StaticQuery
    query={query}
    render={data => <Footer data={data} {...props} />}
  />
)

