import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
    max-width:1038px;
    width:100%;
    margin-left:auto;
    margin-right:auto;
    box-sizing: border-box;
    position:relative;
`

export const Container = ({children, className, styles}) => {
    return (
        <Wrapper>
            <div styles={styles} className={`container ${className ? ` ${className}` : ''}`}>{children}</div>
        </Wrapper>
    )
}

export const Row = ({children, className}) => {
    return <div className={`row ${className ? ` ${className}` : ''}`}>{children}</div>
}

export const Col = ({children, xs, sm, md, lg, xsOffset, smOffset, mdOffset, lgOffset, className}) => {

    let classes = ``

    if(xs) classes += `col-xs-${xs} `

    if(sm) classes += `col-sm-${sm} `

    if(md) classes += `col-md-${md} `

    if(lg) classes += `col-lg-${lg} `

    if(xsOffset) classes += `col-xs-offset-${xsOffset} `

    if(smOffset) classes += `col-sm-offset-${smOffset} `

    if(mdOffset) classes += `col-md-offset-${mdOffset} `

    if(lgOffset) classes += `col-lg-offset-${lgOffset} `

    // classes = classes.substring(0, classes.length - 1)

    return <div className={`${classes}  ${className ? ` ${className}` : ''}`}>{children}</div>
}

export const Center = styled.div`
    text-align:center;
`
export const Left = styled.div`
    text-align:left;
`
export const Right = styled.div`
    text-align:right;
`