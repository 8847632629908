import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Header from "./header"
import Footer from "./footer"

const Page = styled.div`

${props => props.theme === 'afr' ? 
  `
  font-family: suecanano_regular, Georgia, Times, serif;
  ` : 
  `
  font-family: pt-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
  `
}

`

const Layout = ({ children }) => {
  return (
    <Page theme={process.env.GATSBY_THEME}>
      <Header/>
      {children}
      <Footer />
    </Page>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
