import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import ArrowIMG from "../../images/arrow.svg"

const Styles = {

    Solid: styled.button`
        background-color:#0F6CC9;
        color:white;
        border-radius:6px;
        height:42px;
        line-height:42px;
        text-transform:uppercase;
        font-size:12px;
        font-weight:bold;
        margin:20px 0px;
        padding:0px 30px;
        appearance: none;
        border:none;
        outline:none;

        &:hover{
            background-color:#04498D;
        }

        &:disabled{
            opacity:.5;
        }

    `,
    
    SolidBlue: styled.button`
        background-color:#0F6CC9;
        color:white;
        border-radius:6px;
        height:42px;
        line-height:42px;
        text-transform:uppercase;
        font-size:12px;
        font-weight:bold;
        margin:20px 0px;
        padding:0px 30px 0px 30px;
        appearance: none;
        border:none;
        outline:none;

        &:hover{
            background-color:#04498D;
        }

        &:disabled{
            opacity:.5;
        }

    `,

    Inline: styled.button`
        appearance: none;
        border:none;
        outline:none;
        color:#0F6CC9;
        font-weight:bold;
        font-size:14px;
        cursor: pointer;
        background-color:transparent;

        img{
            width:11px;
            margin-left:4px;
            transition:transform, .25s;
        }

        &:hover{
            img{
                transform:translateX(4px);
            }
        }


    `

}

function Button({type, style, label, onClick, disabled}){

    let btn

    switch(style){
        case "solid":
            btn = (<Styles.Solid type={type} disabled={disabled}>{label}</Styles.Solid>)
        break;
        
        case "blue":
            btn = (<Styles.SolidBlue type={type} disabled={disabled}>{label}</Styles.SolidBlue>)
        break;
        
        case "inline":
            btn = (<Styles.Inline type={type} disabled={disabled}>{label} <img src={ArrowIMG} /></Styles.Inline>)
        break;

        default:
            btn = (<Styles.Solid type={type} disabled={disabled}>{label}</Styles.Solid>)
    }

    return btn
}

Button.propTypes = {
    type: PropTypes.string.isRequired,
    style: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
}

Button.defaultProps = {
    type: "button",
    style: "solid",
}

export default Button