import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

import { Container, Row, Col, Center, Right } from "./shared/grid"
import { H2, P } from "./shared/text"

import AFRLogoIMG from "../images/logo-new.svg"
import SMHLogoIMG from "../images/smh-logo.svg"
import AGELogoIMG from "../images/age-logo.svg"
import GlowIMG from "../images/glow.png"

const Styles = {

  Wrapper: styled.header`
    background: rgb(104,103,103);
    ${props => props.theme === 'afr' ?
      `background: radial-gradient(circle, rgba(104,103,103,1) 0%, rgba(46,46,46,1) 100%);`: 
      `background: #0A162E;
       background-image:url(${GlowIMG});
       background-position:bottom center;
       background-size:2656px 299px;
       background-repeat:no-repeat;
      `
    }

    display:flex;
    padding-bottom:100px;

    @media (min-width: 768px){
      padding-bottom:120px;
    }

    @media (min-width: 1038px){
      padding-bottom:130px;
    }

    a{
      text-decoration:none;
      color:white;
    }
  `,

  SMLogo: styled.img`
    margin-left:auto;
    margin-right:auto;
    width:200px;
    margin-top:30px;
    margin-bottom:20px;

    @media (min-width: 768px){
      display:none;
    }
  `,

  MDLogo: styled.img`
    display:none;

    @media (min-width: 768px){
      display:block;
      padding:0px 0px;
      width:90%;
    }

    @media (min-width: 1038px) {
      width:65%;
    }
  `,

  CopyWrapper: styled.div`
    padding:14px;

    @media (min-width: 768px){
      margin-top:70px;
      margin-bottom:70px;
    }
  `,

  QuestionsLink: styled.span`
    display:none;
    
    @media (min-width: 768px){
      font-size:14px;
      text-decoration:underline;
      color:white;
      margin-right:20px;
      display:inline-block;
      cursor:pointer;
    }
  `,

  Row: styled(Row)`

    @media (min-width: 768px){
      margin-top:20px;
      margin-bottom:20px;
      padding:10px 0px;
    }
  `,

  Button: styled.div`
    display:none;
    color:white;
    cursor: pointer;
    
    @media (min-width: 768px){
      display:inline-block;
      border:1px solid white;
      padding:10px 20px;
      font-size:12px;
      border-radius:6px;
      height:18px;
      line-height:20px;
      transition: opacity, .5s;
    }

    &:hover{
      opacity:.5;
    }
  `,
  
  ButtonBlue: styled.div`
    display:none;
    color:white;
    cursor: pointer;
    background-color:#096DD2;
    
    @media (min-width: 768px){
      display:inline-block;
      border:1px solid #096DD2;
      padding:10px 20px;
      font-size:12px;
      letter-spacing:1px;
      border-radius:6px;
      height:18px;
      line-height:20px;
      transition: opacity, .5s;
    }

    &:hover{
      background-color:#044F9A;
      border:1px solid #044F9A;
    }
  `,

}

const Header = ({ data }) => {

  const block = data.allContentfulBlock.blocks[0].block

  let LOGO = AFRLogoIMG
  let BUTTON = <Styles.Button onClick={() => scrollTo('#form')}>{block.ctaText}</Styles.Button>
  
  if(process.env.GATSBY_THEME === 'afr'){
    LOGO = AFRLogoIMG
    BUTTON = <Styles.Button onClick={() => scrollTo('#form')}>{block.ctaText}</Styles.Button>
  }

  if(process.env.GATSBY_THEME === 'smh'){
    LOGO = SMHLogoIMG
    BUTTON = <Styles.ButtonBlue onClick={() => scrollTo('#form')}>{block.ctaText}</Styles.ButtonBlue>
  }

  if(process.env.GATSBY_THEME === 'age'){
    LOGO = AGELogoIMG
    BUTTON = <Styles.ButtonBlue onClick={() => scrollTo('#form')}>{block.ctaText}</Styles.ButtonBlue>
  }
  
  return (
    <Styles.Wrapper 
      theme={process.env.GATSBY_THEME}
    >
      <Container>
        <Styles.Row>
          <Col xs={12} md={4}>
            <Center>
              <Styles.SMLogo src={LOGO} alt="logo" />
            </Center>
            <Styles.MDLogo src={LOGO} alt="logo" />
          </Col>
          <Col xs={0} md={8}>
            <Right>
              <Styles.QuestionsLink onClick={() => scrollTo('#faqs')}>Got questions?</Styles.QuestionsLink>
              {BUTTON}
            </Right>
          </Col>
        </Styles.Row>
        <Row>
          <Col md={8} mdOffset={2} lg={8} lgOffset={2}>
            <Center>
              <Styles.CopyWrapper>
                <H2 color="white">{block.title}</H2>
                <Row>
                  <Col lg={8} lgOffset={2}>
                    <P color="white">{block.description.text}</P>
                  </Col>
                </Row>
              </Styles.CopyWrapper>
            </Center>
          </Col>
        </Row>
      </Container>
    </Styles.Wrapper>
  )
  
}

const query = graphql`
query{
  allContentfulBlock(limit:1, filter:{slug:{eq:"header"}}){
    blocks:edges{
      block:node{
        title
        description{
          text:description
        }
        ctaText
      }
    }
  }
}
`

export default props => (
  <StaticQuery
    query={query}
    render={data => <Header data={data} {...props} />}
  />
)