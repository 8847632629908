import React from "react"
import styled from "styled-components"

export const H2 = styled.h2`

    ${process.env.GATSBY_THEME === 'afr' ? 
       `font-family: sueca_hd_regular;
       `
       :
       `
       font-family: abril-titling, serif;
       font-weight: 600;
       font-style: normal;
       ` 
    }

    font-size:28px;
    line-height:38px;
    color:${props => props.color || "#000"};
    margin-top:0px;

    @media (min-width: 980px){
        
        ${process.env.GATSBY_THEME === 'afr' ? 
        `font-family: sueca_hd_regular;
        font-size:38px;
        line-height:54px;
        `
        :
        `
        font-family: abril-titling, serif;
        font-weight: 600;
        font-style: normal;
        font-size:42px;
        line-height:54px;
        ` 
        }
    }
`

export const H3 = styled.h3`

    ${process.env.GATSBY_THEME === 'afr' ? 
       `font-family: sueca_hd_regular;`
       :
       `
       font-family: abril-titling, serif;
       font-weight: 600;
       font-style: normal;
       ` 
    }

    font-size:21px;
    line-height:31px;
    color:${props => props.color || "#000"};
    margin-top:0px;
    margin-bottom:10px;

    @media (min-width: 980px){
        font-size:28px;
        line-height:38px;
    }
`

export const P = styled.p`
    font-size:16px;
    line-height:24px;
    color:${props => props.color || "#555"};
    margin-top:0px;
`

export const Span = styled.span`
    font-size:14px;
    line-height:21px;
    color:${props => props.color || "#555"};
    margin-top:0px;
`
